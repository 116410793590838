export default {
    api: [
        { fnName: "getLogout", type: "get", url: "/zsc/user/logout" }, //退出登录
        { fnName: "postLogin", type: "post", url: "/zsc/user/loginNew" }, //密码登录
        { fnName: "postRegister", type: "get", url: "/zsc/user/registerUser" }, //注册  //post失败,get可以
        { fnName: "postLoginVersion", type: "post", url: "/zsc/user/loginVersion" }, //记录登录版本
        { fnName: "postUpdatePassword", type: "get", url: "/zsc/user/updatePassword" }, //改密
        { fnName: "getSendMsg", type: "get", url: "/zsc/user/sendMsg" }, //发送验证码
        { fnName: "postCodeLogin", type: "post", url: "/zsc/user/codeLoginNew" }, //验证码登录
        { fnName: "getUserInfo", type: "get", url: "/zsc/user/getUserInfo" }, //获取用户信息
        { fnName: "getQueryUserInfo", type: "get", url: "/zsc/user/queryUserInfo" }, //查询指定用户信息
        { fnName: "getUserWallet", type: "get", url: "/zsc/user/queryUserWallet" }, //查询用户财富
        { fnName: "postAccessRecords", type: "post", url: "/zsc/loginInfoGather/gatherWebLoginInfo" }, //网页访问信息收集
        //图片上传
        { fnName: "postUploadFiles", type: "post", url: "/zsc/upload/uploadFiles" },
        //oss直传
        { fnName: "getUploadSignature", type: "get", url: "/zsc/upload/generateUploadSignature" },
        //首页相关
        { fnName: "getPartyList", type: "get", url: "/zsc/user/partyList" }, //我的派对
        { fnName: "getOwnChat", type: "get", url: "/zsc/chat/getOwnChat" }, //我的房间
        { fnName: "getHome", type: "get", url: "/zsc/chat/recommendRoomListNew" }, //首页
        //{ fnName: "getBanner", type: "get", url: "/zsc/findFind/queryRecommendList" }, 派对banner
        { fnName: "getBanner", type: "get", url: "/zsc/appAdvertisingPosition/list" },//统一banner
        { fnName: "getRankingListNew", type: "get", url: "/zsc/user/rankingListNew" }, //红人榜
        { fnName: "getRankingList", type: "get", url: "/zsc/user/rankingList" }, //派对榜
        { fnName: "getGiftWallRankingList", type: "get", url: "/zsc/user/giftWallRankingList" }, //礼物榜
        { fnName: "getGiftWall", type: "get", url: "/zsc/user/giftWall" }, //获取礼物墙
        { fnName: "getVipCard", type: "get", url: "/zsc/user/getVipCardList" }, //获取vip特权卡
        { fnName: "postChangeVipCardStatus", type: "post", url: "/zsc/user/changeVipCardStatus" }, //修改vip卡状态
        { fnName: "getRandomRoomName", type: "get", url: "/zsc/chat/getRandomRoomName" }, //随机房间名
        { fnName: "postNewOperateGameRoom", type: "post", url: "/zsc/chat/newOperateGameRoom" }, //切换更新房间信息(语聊房、游戏房)
        { fnName: "getFriendsInviteList", type: "get", url: "/zsc/game/getFriendsInviteList" }, //搜索好友
        //脱单盲盒
        { fnName: "getDivorceBoxData", type: "get", url: "/zsc/divorceBox/getDivorceBoxData" }, //脱单盲盒-首页
        { fnName: "getFetchNote", type: "get", url: "/zsc/divorceBox/fetchNote" }, //脱单盲盒-抽取纸条
        { fnName: "postPutNote", type: "post", url: "/zsc/divorceBox/putNote" }, //脱单盲盒-放入纸条
        { fnName: "getMyFetchNote", type: "get", url: "/zsc/divorceBox/getMyFetchNote" }, //脱单盲盒-已抽到
        { fnName: "getMyPutNote", type: "get", url: "/zsc/divorceBox/getMyPutNote" }, //脱单盲盒-已放入
        { fnName: "getDeleteMyPutNote", type: "get", url: "/zsc/divorceBox/deleteMyPutNote" }, //脱单盲盒-销毁
        { fnName: "getCheckCanChat", type: "get", url: "/zsc/divorceBox/checkCanChat" }, //脱单盲盒-私聊校验并打招呼

        //个人中心

        { fnName: "getCheckBanned", type: "get", url: "/zsc/dataBanned/checkBanned" }, //星钻转星尘
        { fnName: "postMoneyToDiamond", type: "post", url: "/zsc/user/moneyToDiamond" }, //星钻转星尘
        { fnName: "getQueryCashOut", type: "get", url: "/zsc/user/queryCashOut" }, //星钻转星尘明细
        { fnName: "getUserDalongContractNew", type: "get", url: "/zsc/user/getUserDalongContractNew" }, //是否签约
        { fnName: "getCashOut", type: "get", url: "/zsc/user/cashOut" }, //提现
        { fnName: "getUserWithdrawalBindingInfo", type: "get", url: "/zsc/user/getUserWithdrawalBindingInfo" }, //获取提现绑定id
        { fnName: "getFunctionSwitchStatus", type: "get", url: "/zsc/user/getFunctionSwitchStatus" }, //获取功能开关状态
        { fnName: "getBindAliPay", type: "get", url: "/zsc/user/bindAliPay" }, //绑定支付宝账号
        { fnName: "putBindBankCard", type: "put", url: "/zsc/user/bindBankCard" }, //绑定银行卡账号
        { fnName: "getUserRichesAndCharm", type: "get", url: "/zsc/small/richesAndCharm/getUserRichesAndCharm" }, //财富魅力等级
        { fnName: "getNobleDetail", type: "get", url: "/zsc/noble/detail" }, //贵族说明
        { fnName: "postNobleBuy", type: "post", url: "/zsc/noble/buy" }, //贵族购买
        { fnName: "getGiftWallList", type: "get", url: "/zsc/user/giftWallList" }, //收礼明细
        { fnName: "getSendGiftList", type: "get", url: "/zsc/user/sendGiftList" }, //送礼明细
        { fnName: "getUserDressUp", type: "get", url: "/zsc/user/userDressUp" }, //装扮中心
        { fnName: "postUseDressUp", type: "post", url: "/zsc/user/useDressUp" }, //使用装扮
        { fnName: "getGalaxyList", type: "get", url: "/zsc/galaxy/galaxyList" }, //心网列表
        { fnName: "getRelationshipList", type: "get", url: "/zsc/galaxy/relationshipList" }, //心网关系列表
        { fnName: "showHideRelationship", type: "post", url: "/zsc/galaxy/showHide" }, //显示隐藏心网关系
        { fnName: "postDeleteGalaxy", type: "post", url: "/zsc/galaxy/deleteGalaxy" }, //删除心网关系
        { fnName: "getAddBlackList", type: "get", url: "/zsc/user/addBlackList" }, //拉黑
        { fnName: "setUserRemarks", type: "post", url: "/zsc/user/setUserRemarks" }, //设置备注
        { fnName: "getBlackList", type: "get", url: "/zsc/user/getBlackList" }, //个人黑名单列表
        { fnName: "getRemoveBlackList", type: "get", url: "/zsc/user/removeBlackList" }, //移除个人黑名单列表
        { fnName: "putUpdateUserInfoNew", type: "put", url: "/zsc/user/updateUserInfoNew" }, //更新个人信息
        { fnName: "putUpdateHeadNew", type: "put", url: "/zsc/user/updateHeadNew" }, //更新头像
        { fnName: "postRedeemActivationCode", type: "post", url: "/zsc/user/redeemActivationCode" }, //兑换激活码
        { fnName: "postUpdateUserMobileCheck", type: "post", url: "/zsc/user/updateUserMobileCheck" }, //原手机号验证
        { fnName: "postUpdateUserMobile", type: "post", url: "/zsc/user/updateUserMobile" }, //新手机号验证
        { fnName: "getCancellationList", type: "get", url: "/zsc/user/cancellationList" }, //永久注销账号-获取注销条件
        { fnName: "getSendCancellationMsg", type: "get", url: "/zsc/small/user/sendCancellationMsg" }, //永久注销账号-发送注销验证码
        { fnName: "getCancellation", type: "get", url: "/zsc/small/user/cancellation" }, //永久注销账号-确认注销
        { fnName: "getWalletDetails", type: "get", url: "/zsc/user/getWalletDetails" }, //获取星钻星尘明细
        { fnName: "getWithdrawalRecord", type: "get", url: "/zsc/user/getWithdrawalRecord" }, //获取提现记录
        { fnName: "getUserPrivacy", type: "get", url: "/zsc/user/userPrivacy" }, //获取用户隐私配置
        { fnName: "postUserPrivacy", type: "post", url: "/zsc/user/userPrivacy" }, //设置用户隐私配置
        { fnName: "getMedalInfo", type: "get", url: "/zsc/medal/info" }, //获取勋章墙
        { fnName: "getMedalDetail", type: "get", url: "/zsc/medal/detail" }, //获取勋章详情
        { fnName: "postMedalEquip", type: "post", url: "/zsc/medal/equip" }, //佩戴或取消佩戴勋章
        { fnName: "getClassificationList", type: "get", url: "/zsc/appShop/getClassificationList" }, //商城--获取商城分类列表
        { fnName: "getPropByClassification", type: "get", url: "/zsc/appShop/getPropByClassification" }, //商城--通过分类查询商城道具
        { fnName: "getNewArrivalAndHotRecommend", type: "get", url: "/zsc/appShop/newArrivalAndHotRecommend" }, //商城--热门推荐
        { fnName: "postBuyProp", type: "post", url: "/zsc/appShop/buyProp" }, //商城--购买道具
        { fnName: "getKnapsackProps", type: "get", url: "/zsc/user/getKnapsackProps" }, //背包--获取用户背包
        { fnName: "getKnapsackType", type: "get", url: "/zsc/user/getKnapsackType" }, //背包--获取背包类型
        { fnName: "postNewUseDressUp", type: "post", url: "/zsc/user/newUseDressUp" }, //背包--穿戴、取消穿戴
        { fnName: "getCheckPropIsHave", type: "get", url: "/zsc/appShop/checkPropIsHave" }, //校验是否拥有道具和对应购买货币数量

        { fnName: "getFollowList", type: "get", url: "/zsc/fans/getFollow" }, //获取关注列表
        { fnName: "getFansList", type: "get", url: "/zsc/fans/getFans" }, //获取粉丝列表
        { fnName: "getQueryAccessRecordList", type: "get", url: "/zsc/accessRecord/queryAccessRecord" }, //获取最近来访列表
        { fnName: "getQueryVisitorRecordList", type: "get", url: "/zsc/accessRecord/queryVisitorRecord" }, //获取浏览足迹列表
        { fnName: "postOperateStealthFootprint", type: "post", url: "/zsc/user/operateStealthFootprint" }, //开启、关闭隐身访问
        // {fnName:"getHome",type:"get",url:[""]},
        { fnName: "getGlobalSearch", type: "get", url: "/zsc/game/globalSearch" }, //全局搜索
        { fnName: "getUserStatus", type: "get", url: "/zsc/user/selectBannedUser" }, //查询用户状态
        { fnName: "getChatTypeList", type: "get", url: "/zsc/chat/getChatTypeList" }, //大厅房间类型
        { fnName: "getChatHostList", type: "get", url: "/zsc/chat/queryChatHostList" }, //大厅房间列表
        //加入语聊房
        { fnName: "checkAccompanyStatus", type: "get", url: "/zsc/chat/checkAccompanyStatus" }, //检查房间状态
        { fnName: "checkRoomAccess", type: "get", url: "/zsc/chat/checkRoomAccess" }, //检查进入房间权限
        { fnName: "checkRoomAccessNew", type: "get", url: "/zsc/chat/checkRoomAccessNew" }, //检查进入房间权限-新
        { fnName: "availableRoomList", type: "get", url: "/zsc/chat/availableRoomList" }, //可用房间列表
        { fnName: "intoChatRoom", type: "get", url: "/zsc/chat/intoChatRoom" }, //进入语聊房
        { fnName: "exitLiveRoom", type: "get", url: "/zsc/chat/exitLiveRoom" }, //退出房间
        { fnName: "activityPictureUserList", type: "get", url: "/zsc/chat/activityPictureUserList" }, //房间活动图片组件-用户展示列表
        { fnName: "getActivityList", type: "get", url: "/zsc/chat/activityList" }, //房间活动入口
        { fnName: "getPlayList", type: "get", url: "/zsc/activity/checkAllActivityInfo" }, //房间玩法入口
        { fnName: "getActivityCondition", type: "get", url: "/zsc/chat/showActivitiesOrNot" }, //根据用户等级判断是否显示玩法入口
        { fnName: "getActivitySwitch", type: "get", url: "/zsc/chat/activitySwitch" }, // 活动开关 1:星际探索 2:星河许愿 3:摘星阁 0/1是否显示
        { fnName: "getFirstRechargeStatus", type: "get", url: "/zsc/user/getFirstRechargeStatus" }, //是否首冲过
        { fnName: "getIntoOwnChat", type: "get", url: "/zsc/chat/intoOwnChat" }, //房间不存在会创建房间

        //聊天
        { fnName: "getFriendsListNew", type: "get", url: "/zsc/fans/getFriendsListNew" }, //获取聊天列表
        { fnName: "getCanPrivateChat", type: "get", url: "/zsc/user/canPrivateChat" }, //是否能私聊
        { fnName: "getCheckUserMessagePermission", type: "get", url: "/zsc/user/checkUserMessagePermission" }, //私信权限验证
        { fnName: "getPlayHostGames", type: "get", url: "/zsc/play/getPlayHostGames" }, //
        // https://gl.cdddian.cn/zsc/user/canPrivateChat?queryUserId=1011655
        // https://gl.cdddian.cn/zsc/user/queryUserInfo?userId=1011655
        // https://gl.cdddian.cn/zsc/user/checkUserMessagePermission?receiveUserId=1011655
        // https://gl.cdddian.cn/zsc/play/getPlayHostGames?userId=1011655

        //房间操作
        { fnName: "getOnMicrophone", type: "get", url: "/zsc/chat/onMicrophone" }, //上下麦
        { fnName: "postCloseMicrophone", type: "post", url: "/zsc/chat/closeMicrophone" }, //闭麦
        { fnName: "getSetLockMicrophone", type: "get", url: "/zsc/chat/setLockMicrophone" }, //锁麦
        { fnName: "getSetBossLocation", type: "get", url: "/zsc/chat/setBossLocation" }, //设置老板位
        { fnName: "getChatRoomUserInfo", type: "get", url: "/zsc/chat/getChatRoomUserInfo" }, //获取房间用户信息
        { fnName: "getAppointAdmin", type: "get", url: "/zsc/chat/appointAdmin" }, //设置为房间管理
        { fnName: "postAppointSuperAdmin", type: "post", url: "/zsc/chat/appointSuperAdmin" }, //设置为超级管理
        { fnName: "postAddChatBannedList", type: "post", url: "/zsc/chat/addChatBannedList" }, //禁言
        { fnName: "postRemoveChatBannedList", type: "post", url: "/zsc/chat/removeChatBannedList" }, //取消禁言
        { fnName: "getKickOut", type: "get", url: "/zsc/chat/kickout" }, //踢出房间
        { fnName: "getFollow", type: "get", url: "/zsc/fans/follow" }, //关注用户
        { fnName: "getUnFollow", type: "get", url: "/zsc/fans/unfollow" }, //取消关注用户
        { fnName: "getInitiateAccuse", type: "get", url: "/zsc/accuse/initiateAccuse" }, //举报用户
        { fnName: "getReport", type: "get", url: "/zsc/accuse/getReport" }, //举报原因
        { fnName: "getInvitesUserMicrophone", type: "get", url: "/zsc/chat/adminInvitesUserMicrophone" }, //房主邀请上麦
        { fnName: "postApplyForMicrophone", type: "post", url: "/zsc/chat/applyForMicrophone" }, //申请上麦
        { fnName: "postAgreeOrRejectMicrophone", type: "post", url: "/zsc/chat/agreeOrRejectMicrophone" }, //同意或拒绝上麦
        { fnName: "getApplyForMicrophoneList", type: "get", url: "/zsc/chat/applyForMicrophoneList" }, //申请上麦列表
        { fnName: "putRoomEncryptionAndDecryption", type: "put", url: "/zsc/chat/roomEncryptionAndDecryption" }, //房间上锁与解锁
        { fnName: "getCollectionChatRoom", type: "get", url: "/zsc/chat/collectionChatRoom" }, //房间收藏与取消收藏
        { fnName: "getResetGiftScore", type: "get", url: "/zsc/chat/resetGiftScore" }, //清空房间礼物积分
        { fnName: "postClearGiftScore", type: "post", url: "/zsc/chat/clearGiftScore" }, //清空指定用户礼物值
        { fnName: "postStartAuction", type: "post", url: "/zsc/chat/activity/startAuction" }, //开启房内排行榜
        { fnName: "postEndAuction", type: "post", url: "/zsc/chat/activity/endAuction" }, //关闭房内排行榜
        { fnName: "getSearchRoom", type: "get", url: "/zsc/chat/activity/searchRoom" }, //搜索房间
        { fnName: "postStartInternalPk", type: "post", url: "/zsc/chat/activity/startInternalPk" }, //开启房内pk
        { fnName: "getEndInternalPk", type: "get", url: "/zsc/chat/activity/endInternalPk" }, //结束房内pk
        { fnName: "postApplyForPk", type: "post", url: "/zsc/chat/activity/applyForPk" }, //跨厅pk
        { fnName: "getCancelPk", type: "get", url: "/zsc/chat/activity/cancelPk" }, //结束跨厅pk
        { fnName: "getRejectPk", type: "get", url: "/zsc/chat/activity/rejectPk" }, //拒绝跨厅pk
        { fnName: "getAcceptPk", type: "get", url: "/zsc/chat/activity/acceptPk" }, //接受跨厅pk
        { fnName: "getEarlyTerminationPk", type: "get", url: "/zsc/chat/activity/earlyTerminationPk" }, //提前结束跨厅pk
        { fnName: "postStartCampPk", type: "post", url: "/zsc/chat/camp/startCampPk" }, //开启红蓝pk
        { fnName: "getEndCampPk", type: "get", url: "/zsc/chat/camp/endCampPk" }, //结束红蓝pk
        { fnName: "getCheckCampUserIsReward", type: "get", url: "/zsc/chat/camp/checkCampUserIsReward" }, //红蓝pk是否打赏
        { fnName: "postReceiveRecord", type: "post", url: "/zsc/chat/camp/receiveRecord" }, //红蓝pk领取彩蛋
        { fnName: "getCampConfigInfo", type: "get", url: "/zsc/chat/camp/getCampConfigInfo" }, //红蓝pk获取彩蛋下一级pk值
        { fnName: "getChatClearScreen", type: "get", url: "/zsc/chat/chatClearScreen" }, //清理公屏
        { fnName: "postTransferDiamond", type: "post", url: "/zsc/user/diamondSendToSomeone" }, //转赠操作
        { fnName: "getTransferDiamondSwitchStatus", type: "get", url: "/zsc/user/getDiamondSwitchStatus" }, //转赠开关状态
        //房间管理
        { fnName: "getRoomDetail", type: "get", url: "/zsc/chat/roomDetails" }, //获取房间详情
        { fnName: "getChatRoomItem", type: "get", url: "/zsc/chat/chatRoomItem" }, //获取房间管理员列表
        { fnName: "getCustomChatRoomBackground", type: "get", url: "/zsc/chat/customChatRoomBackground" }, //获取自定义背景图片
        { fnName: "postCustomChatRoomBackground", type: "post", url: "/zsc/chat/customChatRoomBackground" }, //添加自定义背景图片
        { fnName: "postUpdateChatRoom", type: "post", url: "/zsc/chat/updateChatRoom" }, //更换背景图片
        { fnName: "postChatRoomBackground", type: "post", url: "/zsc/chat/getChatRoomBackground" }, //获取房间背景图片
        { fnName: "postCutOverMicMode", type: "post", url: "/zsc/chat/cutoverMicMode" }, //切换邀请上麦
        { fnName: "getWitchWheatPosition", type: "get", url: "/zsc/chat/radioStation/witchWheatPosition" }, //电台厅特有，切换五麦位模式
        { fnName: "getRadioStationMicSwitch", type: "get", url: "/zsc/chat/radioStation/radioStationMicSwitch" }, //电台厅特有，切换连麦模式
        { fnName: "getChatBannedList", type: "get", url: "/zsc/chat/chatBannedList" }, //禁言列表
        { fnName: "getRoomBlackList", type: "get", url: "/zsc/chat/roomBlackList" }, //黑名单列表
        { fnName: "postRemoveRoomBlackList", type: "post", url: "/zsc/chat/removeRoomBlackList" }, //移出黑名单列表
        { fnName: "getChatOperationRecordList", type: "get", url: "/zsc/chat/chatOperationRecordList" }, //获取房间操作日志
        { fnName: "getHistoryFlowList", type: "get", url: "/zsc/chat/historyFlowList" }, //获取房间历史流水
        { fnName: "postSwitchSendPicPublic", type: "post", url: "/zsc/chat/switchSendPicPublic" }, //切换禁止公屏发送图片
        { fnName: "postSwitchLowLevelMsgSend", type: "post", url: "/zsc/chat/switchLowLevelMsgSend" }, //切换1级用户公屏发言
        //相亲房
        { fnName: "postStateSwitch", type: "post", url: "/zsc/chat/blindDate/stateSwitch" }, //相亲房下一步
        { fnName: "postStageRollback", type: "post", url: "/zsc/chat/blindDate/stageRollback" }, //相亲房上一步
        { fnName: "postBlindDateShowChoose", type: "post", url: "/zsc/chat/blindDate/showChoose" }, //相亲房揭示心动
        { fnName: "postBindDateChoose", type: "post", url: "/zsc/chat/blindDate/choose" }, //相亲房心动选择
        //礼物相关
        {
            fnName: "getGiftList",
            type: "get",
            url: [
                "/zsc/gift/getGift",
                "/zsc/gift/getNobleGift",
                "/zsc/gift/getNewUniqueGift",
                "/zsc/activity/activityInfoByPlayRelevance",
                // "/zsc/chat/blindBoxListNew"
                "/zsc/galaxy/getGemstoneList",
                "/zsc/user/getVipCardList",
                "/zsc/user/userBackpackNew",
            ],
        }, //初始化礼物列表
        { fnName: "getGiftBoxList", type: "get", url: "/zsc/activity/activityInfoByPlayRelevance" }, //玩法列表
        { fnName: "getGiftBoxSend", type: "get", url: "/zsc/activity/blindBoxSendGift" }, //送礼盒
        { fnName: "getGiftBoxSendNum", type: "post", url: "/zsc/activity/getHuntNum" }, //礼盒赠送次数
        { fnName: "getGiftBoxBuyTicket", type: "get", url: "/zsc/activity/buyTicket" }, //购买礼盒门票
        { fnName: "getGiftBoxDetail", type: "get", url: "/zsc/activity/getActivityDetail" }, //礼盒详情
        { fnName: "getGemstoneList", type: "get", url: "/zsc/galaxy/getGemstoneList" }, //更新宝石列表
        { fnName: "getUserBackpackNew", type: "get", url: "/zsc/user/userBackpackNew" }, //更新背包列表
        { fnName: "getVipCardList", type: "get", url: "/zsc/user/getVipCardList" }, //更新特权卡列表
        { fnName: "getGiveGift", type: "get", url: "/zsc/chat/giveLiveGift" }, //送礼物
        { fnName: "postUseVipCard", type: "post", url: "/zsc/chat/useVipCard" }, //使用vip特权卡
        { fnName: "getDelVipCard", type: "get", url: "/zsc/user/delVipCard" }, //删除vip特权卡
        { fnName: "postUseBackpackGoods", type: "post", url: "/zsc/user/useBackpackGoods" }, //送背包礼物
        { fnName: "postGiveGemstone", type: "post", url: "/zsc/galaxy/giveGemstone" }, //送宝石
        { fnName: "postGalaxyRefuse", type: "post", url: "/zsc/galaxy/refuse" }, //拒绝关系
        { fnName: "postGalaxyAgree", type: "post", url: "/zsc/galaxy/agree" }, //同意关系
        { fnName: "postGiveBlindBox", type: "post", url: "/zsc/chat/useBlindBox" }, //送盲盒
        { fnName: "getUserBackpackWorth", type: "get", url: "/zsc/user/userBackpackFaceValue" }, //获取背包价值
        { fnName: "postGalaxyBuy", type: "post", url: "/zsc/galaxy/buy" }, //购买宝石
        { fnName: "postDiamondToLuckyStar", type: "post", url: "/zsc/user/diamondToLuckyStar" }, //购买幸运星（用于抽盲盒）
        //房间榜单
        { fnName: "getRefreshContributeList", type: "get", url: "/zsc/chat/chatRoomOnlineUser" }, //im断开后手动刷新房间榜单
        { fnName: "getQueryContributeList", type: "get", url: "/zsc/chat/queryContributeList" }, //查询房间在线榜
        { fnName: "getQueryContributeListNew", type: "get", url: "/zsc/chat/queryContributeListNew" }, //查询房间贵宾榜
        { fnName: "getSwitchContributeLisStatus", type: "get", url: "/zsc/chat/showOnlineListOrNot" }, //切换在线榜数据
        { fnName: "getRecharge", type: "get", url: "/zsc/user/webTopUp" }, //充值
        //魔法表情
        { fnName: "getSendDynamicExpression", type: "get", url: "/zsc/chat/sendDynamicExpression" }, //发送魔法表情
        { fnName: "getDynamicExpressionList", type: "get", url: "/zsc/chat/unionDynamicExpressionList" }, //获取魔法表情
        //星河许愿
        { fnName: "getTrevFountain", type: "get", url: "/zsc/trevFountain/detail" }, //许愿数据
        { fnName: "getTrevFountainRecordList", type: "get", url: "/zsc/trevFountain/recordList" }, //许愿纪录
        { fnName: "getTrevFountainUserBackpack", type: "get", url: "/zsc/trevFountain/userBackpack" }, //许愿礼物列表
        { fnName: "postTrevFountainMakeWish", type: "post", url: "/zsc/trevFountain/makeWish" }, //开始许愿
        { fnName: "getTrevFountainRankList", type: "get", url: "/zsc/trevFountain/billboardInfo" }, //许愿榜单
        //星际探索
        { fnName: "getExploreRecordList_back", type: "get", url: "/zsc/spatiotemporal/exploreRecordList" }, //星际探索记录
        { fnName: "getRewardPreview", type: "get", url: "/zsc/spatiotemporal/rewardPreview" }, //星际奖励预览
        { fnName: "getQueryUserStage_back", type: "get", url: "/zsc/spatiotemporal/queryUserStage" }, //星际探索初始化
        { fnName: "getGrandPrize_back", type: "get", url: "/zsc/spatiotemporal/getGrandPrize" }, //星际全服大奖
        { fnName: "postExploreOnce", type: "post", url: "/zsc/spatiotemporal/exploreOnce" }, //星际探索1次
        { fnName: "postExploreTen", type: "post", url: "/zsc/spatiotemporal/exploreTenTimes" }, //星际探索10次
        { fnName: "postExploreFifty", type: "post", url: "/zsc/spatiotemporal/exploreFiftyTimes" }, //星际探索50次
        { fnName: "getExploreMarketList", type: "get", url: "/zsc/appspatiotemporalmart/getList" }, //星际市场
        { fnName: "getExchangeRecord", type: "get", url: "/zsc/appspatiotemporalmart/getExchangeRecord" }, //星际购买记录
        { fnName: "postExchangeProp", type: "post", url: "/zsc/appspatiotemporalmart/exchangeProp" }, //星际市场购买
        { fnName: "postSourceStone", type: "post", url: "/zsc/spatiotemporal/getSourceStone" }, //星际购买源石
        //星际探索新接口
        { fnName: "postNewExplore", type: "post", url: "/zsc/spatiotemporal/newExplore" }, //探索
        { fnName: "getExploreRecordList", type: "get", url: "/zsc/spatiotemporal/exploreRecordList" }, //星际探索记录  //****重复
        { fnName: "getGrandPrize", type: "get", url: "/zsc/spatiotemporal/getGrandPrize" }, // 大奖记录    //****重复
        { fnName: "getRewardPreviewNew", type: "get", url: "/zsc/spatiotemporal/rewardPreviewNew" }, //奖励预览
        { fnName: "getQueryFreeTime", type: "get", url: "/zsc/spatiotemporal/queryFreeTime" }, //免费次数查询
        { fnName: "getQueryUserStage", type: "get", url: "/zsc/spatiotemporal/queryUserStage" }, //查询时空探索进度 //****重复
        
        //摘星阁
        { fnName: "postPickingStarsOne", type: "post", url: "/zsc/appPickingStars/pickingStarsOneTimes"},//抽1次
        { fnName: "postPickingStarsTen", type: "post", url: "/zsc/appPickingStars/pickingStarsTenTimes"},//抽10次
        { fnName: "postPickingStarsThirty", type: "post", url: "/zsc/appPickingStars/pickingStarsThirtyTimes"},//抽30次
        { fnName: "getPickingStarsRecords", type: "get", url: "/zsc/appPickingStars/getRecords"},//抽取记录
        { fnName: "getPickingStarsProps", type: "get", url: "/zsc/appPickingStars/getProps"},//抽奖道具
        { fnName: "getPickingStarsGrandPrize", type: "get", url: "/zsc/appPickingStars/getGrandPrize"},//抽奖公告
        { fnName: "postPickingStarsBuyScroll", type: "post", url: "/zsc/appPickingStars/buyScroll"},//购买卷轴
        //藏宝阁
        // { fnName: "postTreasureGainOne", type: "post", url: "/zsc/treasure/gainOne" },抽1次
        //{ fnName: "postTreasureGainTen", type: "post", url: "/zsc/treasure/gainTen"}, 抽10次
        //{ fnName: "postTreasureGainThirty", type: "post", url: "/zsc/treasure/gainThirty"},抽30次
        //{ fnName: "getTreasureHuntNum", type: "get", url: "/zsc/treasure/getTreasureHuntNum"},获取寻宝神器数量
        //{ fnName: "getTreasureGiftDetail", type: "get", url: "/zsc/treasure/giftDetail"},获取礼物详情
        //{ fnName: "getTreasureBarrage", type: "get", url: "/zsc/treasure/getBarrage"},获取飘屏
        //{ fnName: "postTreasureBuyTicket", type: "post", url: "/zsc/treasure/buyTicket"},购买门票
        //{ fnName: "getTreasureWinningRecord", type: "get", url: "/zsc/treasure/winningRecord"},抽取记录
        //玩法活动
        { fnName: "getActivityDetail", type: "get", url:"/zsc/activity/getActivityDetail"},//获取活动详情
        { fnName: "postHuntNum", type: "post", url:'/zsc/activity/getHuntNum'},//获取寻宝神器数量
        { fnName: "getStartDraw", type: "get", url:'/zsc/activity/draw'},//开始抽奖
        { fnName: "getDrawRecord", type: "get", url:'/zsc/activity/getDrawRecord'},//获取个人抽奖记录
        { fnName: "getGrandPrize", type: "get", url:'/zsc/activity/getGrandPrize'},//获取全服抽奖记录
        { fnName: "getBuyTicket", type: "get", url:'/zsc/activity/buyTicket'},//购买门票
        //{ fnName: "getActivityRules", type: "get", url:'/zsc/activityPlay/activityRules'},活动规则
        { fnName: "getActivityBarrageList", type: "get", url:'/zsc/activity/getActivityBarrageList'},//全服大奖记录
        //首冲
        { fnName: "getFirstRechargeGiftList", type: "get", url: "/zsc/user/daily/topUp/firstRechargeGiftList" }, //首冲奖励列表
        { fnName: "getFirstRechargeLotteryGiftList", type: "get", url: "/zsc/user/daily/topUp/firstRechargeLotteryGiftList" }, //首冲抽奖列表
        { fnName: "getProfileBannerList", type: "get", url: "zsc/ad/getList" }, //个人主页banner入口-每日首冲
        { fnName: "getHaveFirstRechargeLottery", type: "get", url: "zsc/user/daily/topUp/haveFirstRechargeLottery" }, //用户是否首充抽奖
        { fnName: "postFirstRechargeLottery", type: "post", url: "zsc/user/daily/topUp/firstRechargeLottery" }, //首充抽奖
        { fnName: "postGiveUpFirstRechargeLottery", type: "post", url: "zsc/user/daily/topUp/giveUpFirstRechargeLottery" }, //放弃首充抽奖机会
        { fnName: "postDressUpNowRecharge", type: "post", url: "zsc/user/daily/topUp/dressUpNow" }, //穿戴奖励
        { fnName: "getCheckAwardWindow", type: "get", url: "zsc/user/daily/topUp/checkAwardWindow" }, //用户是否需要奖励弹窗
        { fnName: "getErrorCollect", type: "get", url: "/zsc/user/errorCollecting" }, //错误收集
        { fnName: "getMockPay", type: "get", url: "/zsc/small/register/pay" }, //测试
        { fnName: "getMockReturn", type: "get", url: "/zsc/small/register/return" }, //测试
        //群组消息
        { fnName: "getGroupMessageList", type: "get", url: "/zsc/message/list" },
        // 日志
        { fnName: "getLogList", type: "get", url: "/zsc/logReportConfig/getData" },
        { fnName: "postLogStatus", type: "post", url: "/zsc/logReportConfig/setPullStatus" },
        //公会合作邀请
        { fnName: "postGuildCooperationInvite", type: "post", url: "/zsc/message/handleGuildCooperationInvite" },
        //主播加入公会
        { fnName: "postAnchorJoinGuild", type: "post", url: "/zsc/chat/agreeOrRejectUnion" },
        //主播加入特邀
        { fnName: "postAnchorJoinSpecialInvite", type: "post", url: "/zsc/message/handleGuildSpecialInvite" },
    ],
};

<script setup>
import {emojiBaseUrl,emojiUrlMapping} from "@/utils/emoji"
import { compressorImage } from "@/utils/compressorImage";
const store = appStore();
const { userInfo, currentRoomInfo } = storeToRefs(store);

const props = defineProps({
    // currentUser: Object
    currentUserId: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(["onSendMsg", "onSuccess"]);
const isEmoji = ref(false);
const inputEl = ref(null);
const LIMIT = 300; //输入字数限制
const uid = ref(0)
let preventChatTips = '';
let userMessagePermission = {
    isAllowSendNumber: 1,
    isAllowSendPicture: 1
};
// let userMessageCode = 0;
/*onMounted(() => {});
watch(
    () => props.currentUser && props.currentUser.uid,
    val => {
        if (val) {
            getCheckUserMessagePermission(val);
        }
    },
    {
        immediate: true,
    }
);*/
watchEffect(() => {
    uid.value = props.currentUserId
    // console.log(uid.value);
    if (uid.value) {
        getCheckUserMessagePermission(uid.value);
    }
})
let showErrorMsg = {}
function onSendMessageErrorHandler(error) {
    if(!error) return;
    switch (error.code) {
        case 20012://禁言
            Toast({
                message: "您已被禁言！",
                teleport: "body",
                className: "f-pa",
            });
            // getCheckUserMessagePermission(uid.value);
            break;
        case 80001://云端审核拦截
            Toast({
                message: "内容违规！",
                teleport: "body",
                className: "f-pa",
            });
            break;
        // case 80001:
        //     showErrorMsg.payload.showText = '内容违规！'
        //     emit("onSuccess", showErrorMsg);
        //     break;

    }
}
async function sendMsg(event) {
    if (event && event.type === "keydown") {
        ///shift+enter换行，enter发送
        if (!event.shiftKey && event.keyCode == 13) {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
            switchEmoji(false);
        } else return;
    }
    if (!inputEl.value) return;
    const cloneNode = inputEl.value.cloneNode(true);
    cloneNode.querySelectorAll("img").forEach(element => {
        element.replaceWith(element.alt);
    });
    const inputText = cloneNode.innerText;
    if (!inputText) return;
    if (!uid.value) {
        //房间公聊
        if (currentRoomInfo.value.lowLevelMsgPublicSendSwitch && userInfo.value.level < 1) {
            Toast({
                message: "当前房间禁止1级以下用户发言",
                teleport: ".message-channel",
                className: "f-pa",
            });
        } else {
            store.sendMsg({ text: inputText, mType: "Text",IMAuditContentType:"Text",IMAuditContent:inputText })
        }
    } else {
        //私聊
        if (!canChat(false, inputText)) return;
        if (store.timHandle && store.timHandle.isSdkReady && uid.value) {
            store.timHandle
                .sendTextMessage(String(uid.value), inputText, msg => {
                    // console.log(msg);
                    showErrorMsg = msg;
                    emit("onSendMsg", msg);
                }).then(msg => {
                    emit("onSuccess", msg);
                }).catch(onSendMessageErrorHandler);
        }
    }

    inputEl.value.innerHTML = "";
}

//阻止发送图片
function preventSend(e) {
    if (currentRoomInfo.value.picPublicSendSwitch) {
        Toast({
            message: "房间管理员禁止发送图片",
            teleport: ".message-channel",
            className: "f-pa",
        });
        e.preventDefault();
        return true;
    }
    if (currentRoomInfo.value.lowLevelMsgPublicSendSwitch && userInfo.value.level < 1) {
        Toast({
            message: "当前房间禁止1级以下用户发言",
            teleport: ".message-channel",
            className: "f-pa",
        });
        e.preventDefault();
        return true;
    }
}

//发送图片
async function sendImage(event) {
    // await getCheckUserMessagePermission(props.currentUser.uid);
    if (!uid.value) {
        //房间公聊图片
        if (preventSend(event)) return; //再次验证
        try {
            const file = event.target.files[0];
            const maxSize = 5 * 1024 * 1024;
            if (!file) {
                throw "已取消";
                // throw { message: "请上传图片" };
            } else if (!file.type.includes("image")) {
                throw { message: "图片格式不正确" };
            } else if (file.size > maxSize) {
                throw { message: "图片不得超过5MB" };
            }
            const form = new FormData();
            let newImg = await compressorImage(file, "file", 0.5);
            form.append("fileNames", newImg);
            const { code, data } = await fly.postUploadFiles(form);
            if (code === 0 && data[0]) {
                store.sendMsg({ mType: "Image",IMAuditContentType:"Image",IMAuditContent:data[0], imgurl: data[0], imageHeight: 0, imageWidth: 0 });
            } else throw "failed";
        } catch (error) {
            console.log("error :>> ", error);
            Toast({
                message: (error && error.message) || "发送失败",
                teleport: ".message-channel",
                className: "f-pa",
            });
        }
    } else {
        //私聊
        if (!canChat(true)) return;
        if (store.timHandle && store.timHandle.isSdkReady && uid.value) {
            store.timHandle
                .sendImageMessage(String(uid.value), event.target, msg => {
                    showErrorMsg = msg;
                    emit("onSendMsg", msg);
                }).then(msg => {
                    emit("onSuccess", msg);
                }).catch(onSendMessageErrorHandler);

            event.target.value = "";
        }
    }
}
//发送视频
async function sendVideo(event) {
    if (!canChat(true)) return;
    if (store.timHandle && store.timHandle.isSdkReady) {
        store.timHandle
            .sendVideoMessage(String(uid.value), event.target, msg => {
                emit("onSendMsg", msg);
            }).then(msg => {
                emit("onSuccess", msg);
            }).catch(onSendMessageErrorHandler);
        event.target.value = "";
    }
}
//接口获信权限  //能否私聊和私聊权限
async function getCheckUserMessagePermission(userId) {
    try {
        preventChatTips = "";
        // userMessageCode = 0;
        const { code, msg } = await fly.getCanPrivateChat({ queryUserId: userId })
        //if (userId !== uid.value) return;已切换其他聊天用户
        if (code != 0) {
            //preventChatTips = "";
            // } else {if (code === 10059)
            preventChatTips = msg || "请求失败";
            return;
        }
        const { code: permissionCode, msg: permissionMsg, data: permissionData } = await fly.getCheckUserMessagePermission({
            receiveUserId: userId,
            SELF_CATCH_ERROR: true,
        })
        if (permissionCode === 0) {
            userMessagePermission = Object.assign(userMessagePermission, permissionData);
        } else {// if (permissionCode === 10011||permissionCode === 500)
            preventChatTips = permissionMsg || "请求失败";//preventChatTips || 
            // userMessageCode = permissionCode;
            userMessagePermission = null;
        }
    } catch (error) {
        // console.log("error :>> ", error);
        // const err = typeof error === 'object' ? JSON.stringify(error) : error;
        // const information = `<-------WEB CanPrivateChat and checkUserMessagePermission Error----->${err}`
        // fly.getErrorCollect({information})
    }
}

//检查是否能私聊
function canChat(isImage, msg) {
    // const { uid, preventChatTips, userMessagePermission } = props.currentUser;
    if (!uid.value) return false;
    //发送消息前检查
    if (preventChatTips) {
        //不能和当前用户私聊
        Toast({
            message: preventChatTips,
            teleport: ".chat-box-right",
            className: "f-pa",
        });
        return false;
    }
    if (!userMessagePermission) {//typeof preventChatTips !== "string" || 
        //检查能否私聊接口未调用成功或不能和该用户私聊
        Toast({
            message: "发送失败，请稍后再试",
            teleport: ".chat-box-right",
            className: "f-pa",
        });
        //const information = `<-------WEB Error----->\n${store.userId}\n${uid.value}\n错误提示：${preventChatTips}\n错误码：${permissionCode}`;//\n${userMessagePermission}
        //fly.getErrorCollect({ information })
        return false;
    }

    //不能发送图片
    if (isImage && userMessagePermission.isAllowSendPicture !== 1) {
        Toast({
            message: "不被允许发送该类型消息",
            teleport: ".chat-box-right",
            className: "f-pa",
        });
        return false;
    }
    //不能发送数字
    if (!isImage && msg && userMessagePermission.isAllowSendNumber !== 1) {
        // const reg = /^[0-9]*$/;//不允许纯数字
        const reg = /\d{2,}/; //不允许2位以上连续数字
        if (reg.test(msg)) {
            Toast({
                message: "不被允许发送该类型消息",
                teleport: ".chat-box-right",
                className: "f-pa",
            });
            return false;
        }
    }

    return true;
}

//限制长度
function constraint(event) {
    if (event.target.innerText.length > LIMIT) {
        event.preventDefault();
        return;
    }
}

//表情
function switchEmoji(bool) {
    if (typeof bool === "boolean") isEmoji.value = bool;
    else isEmoji.value = !isEmoji.value;
}

function selectEmoji(value,i) {
    if (!inputEl.value) return;
    const emoji = document.createElement("img");
    emoji.src = `${emojiBaseUrl}/${value}`; //https://lf3-static.bytednsdoc.com/obj/eden-cn/hbnpe_lm_wpn/ljhwZthlaukjlkulzlp/xigua_pc/emoji/emoji_
    emoji.alt = i;
    emoji.setAttribute("style", "height:20px; width:20px; vertical-align:middle;");
    inputEl.value.appendChild(emoji);
}

function onPaste(e) {
    //删除粘贴文本样式
    e.stopPropagation();
    e.preventDefault();
    let text = "",
        event = e.originalEvent || e;
    if (event.clipboardData && event.clipboardData.getData) {
        text = event.clipboardData.getData("text/plain");
    } else if (window.clipboardData && window.clipboardData.getData) {
        text = window.clipboardData.getData("Text");
    }
    const length = LIMIT + 1 - e.target.innerText.length;
    text = text.substring(0, length);
    if (document.queryCommandSupported("insertText")) {
        document.execCommand("insertText", false, text);
    } else {
        document.execCommand("paste", false, text);
    }

    // let { clipboardData } = event;
    // let file;
    // let fileCopy;
    // if (clipboardData && clipboardData.files && clipboardData.files.length > 0) {
    //     file = clipboardData.files[0];
    //     // 图片消息发送成功后，file 指向的内容可能被浏览器清空，如果接入侧有额外的渲染需求，可以提前复制一份数据
    //     fileCopy = file.slice();
    // }
    // if (typeof file === "undefined") {
    //     console.warn("file 是 undefined，请检查代码或浏览器兼容性！");
    //     return;
    // }
    // console.log(file);
}
</script>
<template>
    <div class="im-msg-edit-box" :class="{ 'private-chat': uid }">
        <div class="im-msg-ipt no-scrollbar" contenteditable="true" spellcheck="false" placeholder="请输入消息..." ref="inputEl"
            @paste="onPaste" @keydown.enter.exact="sendMsg" @keypress="constraint"></div>
        <div class="send-bottom-box">
            <img class="emoji img-btn" src="@/assets/im_expression_hover.png" @click.stop="switchEmoji"
                v-click-outside="() => switchEmoji(false)" />
            <div class="file img-btn">
                <label :for="`chat-img-input_${uid || 'group'}`" title="上传图片" @click="preventSend">
                    <img src="@/assets/im_pic_hover.png" />
                </label>
                <input :id="`chat-img-input_${uid || 'group'}`" type="file" accept="image/jpeg,image/jpg,image/png"
                    style="display: none" @input="sendImage" />
            </div>
            <!-- <div class="file img-btn" v-if="uid">
                <label :for="`chat-video-input_${uid}`" title="上传视频" @click="preventSend">
                    <img src="@/assets/im_pic_hover.png" />
                </label>
                <input :id="`chat-video-input_${uid}`" type="file" accept="video/mp4"
                    style="display: none" @input="sendVideo" />
            </div> -->
            <div class="send-btn" @click="sendMsg">发送</div>
        </div>
        <transition name="popup">
            <div class="emoji-box" style="background-color: rgb(38, 35, 53)" v-if="isEmoji">
                <div class="emoji-item" v-for="(item, i) in emojiUrlMapping" :key="i"
                    @click.stop="selectEmoji(item,i)">
                    <img :src="`${emojiBaseUrl}/${item}`" :alt="i" />
                    <!--https://lf3-static.bytednsdoc.com/obj/eden-cn/hbnpe_lm_wpn/ljhwZthlaukjlkulzlp/xigua_pc/emoji/emoji_-->
                </div>
            </div>
        </transition>
    </div>
</template>
<style lang="less" scoped>
.im-msg-edit-box {
    position: relative;
    height: 100px;
    padding: 10px;
    border-radius: 8px;
    background: #ffffff12;

    font-size: 16px;
    font-weight: 400;
    color: #ffffff;

    // overflow: hidden;
    &.private-chat {
        height: 120px;
        border-radius: 8px 8px 8px 0;

        .im-msg-ipt {
            height: 60px;
        }
    }

    .im-msg-ipt {
        width: 100%;
        height: 40px;

        line-height: 1.4;

        word-break: break-all;
        overflow-y: auto;
        outline: none;

        &:empty::before {
            content: attr(placeholder);
            opacity: 0.5;
        }
    }

    .send-bottom-box {
        position: absolute;
        bottom: 0;
        right: 0;

        display: flex;
        align-items: center;

        padding: 5px 10px;

        .img-btn {
            width: 28px;
            height: 28px;
            margin-right: 2px;
            cursor: pointer;

            img {
                cursor: pointer;
            }
        }

        .send-btn {
            text-align: center;

            width: 100px;
            height: 40px;
            margin-left: 5px;
            border-radius: 8px;
            line-height: 40px;

            background: #ffffff1a;
            cursor: pointer;
            user-select: none;

            &:hover {
                background: #ffffff33;
            }
        }
    }

    .emoji-box {
        position: absolute;
        bottom: calc(100% + 10px);
        left: 0;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-evenly;
        width: 100%;
        height: 200px;
        padding: 5px;
        border-radius: 8px;
        overflow-y: auto;

        .emoji-item {
            padding: 4px;

            img {
                height: 27px;
                width: 27px;
            }
        }
    }

    .popup-enter-from,
    .popup-leave-to {
        transform: scaleY(0);
    }

    .popup-enter-active,
    .popup-leave-active {
        transform-origin: center bottom;
        transition: transform 0.2s;
    }
}</style>

import IM from "@/im";
import {formatScore} from '@/utils'
import { TUIConversationService } from "@tencentcloud/chat-uikit-engine";
// import Log from "@/utils/log"
// import DisableDevtool from 'disable-devtool';
// import * as Sentry from "@sentry/vue";
// import { genTestUserSig } from '@/utils/generateTestUserSig';
const { VITE_TRTC_APPID, VITE_IM_APPID, VITE_LOGLEVEL,VITE_debug } = import.meta.env;

//聊天室初始化信息
const roomInitData = {
    currentGroupID: "", //语聊房 群id //发送接收语聊房消息
    currentRoomID: 0, //语聊房 房间id
    currentRoomInfo: {}, //当前房间信息
    //lockCurrentRole: 'Member',当前房间锁定角色

    roomPkInfo: {}, //pk请求提示信息

    trtcHandle: null, //trtc操作对象
    srcUrls: [], //礼物特效链接数组
    isOpen3a: true, //是否打开3a， false-关闭， true-打开
    //vipCard: null,用户vip卡
    agreePlayNotice:{
        type:1,// 1表示房间玩法，2表示盲盒玩法,3表示房间活动
        play:sessionStorage.playNotice||false,
        activity:sessionStorage.activityNotice||false,
        blindBox:sessionStorage.blindBoxNotice||false
    },
    openPlayNoticeDialog: false,
    openMilkyWishDialog: false, //许愿
    openSpaceExploreDialog: false, //星际探索
    openPickStarDialog: false, //摘星阁
    openTreasureHouseDialog: false,//藏宝阁
    activityId: 0,//活动id
    openPkResultDialog: false,//pk结果
    openPkEggDialog: false,//pk打开彩蛋
    
    isShowActivity: false,//根据用户等级判断显示玩法
    isShowPkEgg: false,//是否显示pk彩蛋
    isEndEarly: false,//是否提前结束红蓝pk
    showSetting: false, //房间管理
    
    openKfDialog: false,//客服弹窗
    openHeadProfile: null, //用户资料弹窗
    openApplyListDialog: false, //申请列表弹窗
    openReceiveGiftDialog: false, //礼物列表弹窗
    openSelectSeatDialog: 0, //座位选择弹窗  0-不显示 | 1=显示同意上麦 | 2-显示抱上麦
    selectMicUserId: 0, //被抱上麦的用户id

    openVerifyPwdDialog: false, //密码弹窗
    isClickOverlayVerifyPwdDialog: true, //密码弹窗

    lockShowBottomDialog: false,//锁定底部弹窗
    //投诉对话框
    complaintDialog: {
        isShow: false,
        roomId: 0,
        userId: 0,
        roomName: "",
        userName: "",
        targetType: 0,
    },
};

export const appStore = defineStore("app", {
    state: () => ({
        sdkAppId: Number(VITE_TRTC_APPID),
        imAppId: Number(VITE_IM_APPID),
        logLevel: VITE_LOGLEVEL,

        userSig: localStorage.userSig || "",
        userTrtcSig: localStorage.userTrtcSig || "",
        token: localStorage.token || "",
        userInfo: localStorage.userInfo ? JSON.parse(localStorage.userInfo) : null,

        chatType: sessionStorage.chatType ? JSON.parse(sessionStorage.chatType) : null,
        searchHistory: localStorage.searchHistory ? JSON.parse(localStorage.searchHistory) : [],
        ossClient: null,
        Log:null,
        myRole:0,
        role: "",
        /*roleMap:{
            "Owner":4,
            "SuperAdmin":3,
            "Admin":2,
            "ChairAdmin":1,
            "Member":0
        },*/
        roleMap:{
            "Member":0,//成员
            "Chair":1,//主持
            "Admin":2,//管理
            "ChairAdmin":3,//主持管理
            "SuperAdmin":4,//超管
            "ChairSuperAdmin":5,//主持超管
            "Owner":6,//房主
            "ChairOwner":7//主持房主
        },
        roomId: "",
        enterRoomId: "",
        wallet: null,
        // secretKey: '',
        audioDeviceId: "",
        secretId: window.btoa("id"),
        sourceUserId: null,
        // videoDeviceId: '',
        // cameraList: [],
        microphoneList: [],
        logs: [],
        isJoined: false,
        isPublished: false,
        isShared: false,
        remoteStreams: [],
        invitedRemoteStreams: [],
        systemMessageList: [],

        //聊天
        timHandle: null, //tim操作对象
        conversationList: [], //会话列表
        removeIdArr: ["C2C11"], //排除显示的消息会话 //C2C+userId  // "11"-脱单匹配
        isShowChatBox: false, //是否显示私聊框
        followListType: 0, //显示关注列表  //0无|1关注列表|2粉丝列表|3访客列表|4足迹列表
        currentChatUser: null, //当前对话用户信息

        openLoginDialog: false, //登录弹窗
        svgaDataMap: new Map(), //缓存SVGA数据
        showRechargeDialog: false, //充值弹窗
        showCreateRoomDialog: sessionStorage.create_new ? false : true, //首页是否显示创建派对
        inviteFirstCharge: false, //首冲入口弹窗
        showFirstCharge: false, //首冲抽奖弹窗
        firstChargeUrl: "", //iframe首冲活动

        audienceInfo: {}, //观众信息  //用于给观众送礼
        dialogInputPassword: "", //弹出框输入的密码
        //查看其他用户资料
        profile: {
            isOpen: false,
            giftList: [],
            giftTotal: 0,
            userInfo: null,
            vipCard: [],
        },
        ...roomInitData,
    }),
    getters: {
        userId() {
            if (this.userInfo) return this.userInfo.uid;
            else return "";
        },
        mode() {
            if (this.userInfo) return this.userInfo.mode;
            else return "";
        },
        //当前用户角色权限  //0-Member | 2-Admin | 3-SuperAdmin | 4-Owner | 1-ChairAdmin
        /*myRole() {
            return this.roleMap[this.currentRoomInfo.role] || 0
        },*/
        getInitParamsStates() {
            return !!(this.sdkAppId && this.secretKey && this.roomId && this.userId);
        },
        //房间席位信息
        seatInfoList() {
            const currentRoom = this.currentRoomInfo
            if (!currentRoom.chatHosts) return [];
            const seatInfo = currentRoom.chatHosts.map((item, index) => {
                if (index === 0) {
                    item.tips = "主持位";
                } else if (index === 9) {
                    //派单厅特殊处理
                    item.tips = currentRoom.chatType===2 ? "老板位" : "vip位";
                    item.isVip = true;
                } else if (item.bossLocation) {
                    item.tips = "老板位";
                } else {
                    item.tips = index + "号麦位";
                }
                item.mic = index
                // item.choose = item.redisBlindDateChooseDto
                //item.clickEffectLoop = "https://oss.cdddian.com/im/2023-10-17/2b30183aab7348de8c10bbf248a080b7598871.svga"//点击循环播
                //item.clickEffectOnce = "https://oss.cdddian.com/im/2023-10-17/10971c555ac246008829dcb3edab63a5248743.svga"//点击播一次
                item.clickEffect = "https://oss.cdddian.com/im/2023-10-31/edd504d7e8e14449b520d24071255a9f987589.svga"//点击选择
                item.clicked = false
                item.score = formatScore(item.giftScore);
                return item;
            });
            if(currentRoom.radioMode===0 && this.chatRoomType===4){//电台厅单麦位模式
                return seatInfo.slice(0,1)
            }
            return seatInfo
        },
        // 聊天室分类：1-公会 2-个人
        chatRoomClassify(){
            return this.currentRoomInfo.type
        },
        //聊天室类型 1-默认 2-派单 3-相亲 4-电台 5-拍关系厅
        chatRoomType() {
            return this.currentRoomInfo.chatType;
        },
        chatRoomActivityType(){
            return this.currentRoomInfo.activityType
        },
        blindDateThirdStep(){//相亲厅第三阶段
            const info = this.currentRoomInfo
            return (info.chatType==3 && info.blindDateType==3) ? true : false
        },
        //自己的麦位信息
        mySeatInfo() {
            return this.seatInfoList.find(item => item.uid == this.userId);
        },

        //自己是否上麦
        isOnSeat() {
            return !!this.mySeatInfo;
        },

        //自己是否处在闭麦位
        isOnCloseMic() {
            if (!this.mySeatInfo) return null;
            return this.mySeatInfo.closeMicrophone;
        },

        // //未读消息数量
        unReadCountTotal() {
            return this.conversationList.reduce((total, item) => {
                return total + item.unreadCount;
            }, 0);
        },
    },
    actions: {
        //登出清除数据
        async logout() {
            this.trtcHandle?.leaveRTCRoom();
            this.timHandle?.logout();
            this.clearChatRoomState();
            this.token = null;
            this.userSig = null;
            this.userTrtcSig = null;
            this.userInfo = null;
            localStorage.removeItem("token");
            localStorage.removeItem("userSig");
            localStorage.removeItem("userTrtcSig");
            localStorage.removeItem("userInfo");
            // Sentry.setUser(null);
            //百度统计
            //window._hmt = window._hmt || [];
            //window._hmt.push(["_clearUserId", true]);
            //window._hmt.push(["_clearUserProperty", true]);
        },
        async backendLogout(){
            if(this.currentRoomID){
                await fly.exitLiveRoom({ roomid: this.currentRoomID });
            }
            fly.getLogout();
        },
        // 1/3.保存登录注册信息 包含token,userSig,isNew
        setUserInfo(data) {
            this.token = data.token;
            this.userSig = data.userSig;
            this.userTrtcSig = data.userTrtcSig;
            this.userInfo = data;
            localStorage.token = data.token;
            localStorage.userSig = data.userSig;
            localStorage.userTrtcSig = data.userTrtcSig;
            localStorage.userInfo = JSON.stringify(data);
            
            // const user = { id: data.uid, username: data.nickname, data: data.mobile, level: data.level, charm: data.charm };
            // Sentry.setUser(user);

            //百度统计
            /*window._hmt = window._hmt || [];
            window._hmt.push(["_setUserId", data.uid]);
            const user = { id: data.uid, username: data.nickname, tel: data.mobile, level: data.level, charm: data.charm, birthday: data.birthday };
            window._hmt.push(["_setUserProperty", user]);*/
        },
        // 2/3.整体更新用户信息，不包括token,userSig,isNew
        async updateUserInfo() {
            const { data, code } = await fly.getUserInfo();
            if (code === 0) {
                data.aliPayAccount = data.aliPayAcount;
                this.userInfo = data;
                localStorage.userInfo = JSON.stringify(data);

                /*if (this.userInfo.haveFirstRecharge === 1) {
                    //显示首冲奖励弹窗
                    const { data: dataAward, code: codeAward } = await fly.getCheckAwardWindow();
                    if (codeAward === 0) {
                        if (dataAward === 1) {
                            this.inviteFirstCharge = true;
                            return;
                        } else {
                            this.inviteFirstCharge = false;
                        }
                    }
                    //显示首冲抽奖弹窗
                    const { data: dataRecharge, code: codeRecharge } = await fly.getHaveFirstRechargeLottery();
                    if (codeRecharge === 0 && dataRecharge === 1) {
                        this.showFirstCharge = true;
                    }
                }*/
            }
        },

        // 3/3.修改部分用户信息，不包括token,userSig,isNew
        setUserInfoPart(data) {
            this.userInfo = { ...this.userInfo, ...data };
            localStorage.userInfo = JSON.stringify(this.userInfo);
        },
        //打开客服聊天
        openServiceDialog(){//kfId是全局变量，在main.js中注册
            this.openKfDialog = true
            TUIConversationService.switchConversation(`C2C${kfId}`);
        },
        //打开聊天框
        openChatDialog(chatuser) {
            this.isShowChatBox = false;
            this.currentChatUser = null;
            nextTick(() => {
                this.isShowChatBox = true;
                if (chatuser) this.currentChatUser = chatuser;
            });
        },

        //打开充值框
        openRechargeDialog() {
            this.showRechargeDialog = true;
        },

        //分享屏幕
        shareScreen() {
            if (!this.trtcHandle) return;
            if (!this.trtcHandle.screenStream) {
                this.trtcHandle.publishScreen();
            } else {
                this.trtcHandle.unPublishScreen();
            }
        },

        openComplaintDialog(data) {
            this.complaintDialog = {
                ...data,
                isShow: true,
            };
        },
        closeComplaintDialog() {
            this.complaintDialog = {
                isShow: false,
                roomId: 0,
                userId: 0,
                roomName: "",
                userName: "",
                // targetType: 0,
            };
        },
        setChatType(data) {
            if (data) {
                this.chatType = data;
                sessionStorage.chatType = JSON.stringify(data);
            }
        },
        setSearchHistory(data) {
            if (data) {
                const isRepeat = this.searchHistory.includes(data);
                if (isRepeat) return false;
                this.searchHistory = [data, ...this.searchHistory].slice(0, 10);
                localStorage.searchHistory = JSON.stringify(this.searchHistory);
            }
        },
        delSearchHistory() {
            this.searchHistory = [];
            localStorage.removeItem("searchHistory");
        },
        async getUserWallet() {
            const { data, code } = await fly.getUserWallet({ roomId: this.currentRoomID });
            if (code === 0) {
                this.wallet = data;
                this.setUserInfoPart({
                    LuckyStar: data.LuckyStar,
                    diamond: data.diamond,
                    money: data.money,
                    aliPayAccount: data.alipayacount,
                    bankCard: data.bankCard,
                });
                return data;
            }
            // this.userInfo = data;
        },
        async previewUserInfo(userId, page = "detail", newWindow = "_blank") {
            if (!userId) return;
            // sessionStorage.userId = userId
            //新页面打开用户资料
            // if (userId === this.userId) {
            //     const { href } = this.$router.resolve("/profile");
            //     window.open(href, "_blank");
            // } else {
            const { href } = this.$router.resolve({
                path: "/user-profile/" + page,
                query: {
                    [this.secretId]: window.btoa(userId),
                },
            });
            window.open(href, newWindow);
            // }
            //弹窗打开用户资料
            // try {
            //     const [
            //         {
            //             data: { list, total },
            //         },
            //         { data: vipCard },
            //         { data: userInfo },
            //     ] = await Promise.all([fly.getGiftWall({ userId }), fly.getVipCard({ queryUserId: userId }), fly.getQueryUserInfo({ userId })]);
            //     this.profile = {
            //         isOpen: true,
            //         giftList: list,
            //         giftTotal: total,
            //         userInfo,
            //         vipCard: vipCard,
            //     };
            //     if (typeof fn === "function") fn();
            // } catch (error) {}
        },
        //重置聊天室信息
        clearChatRoomState() {
            this.$patch(roomInitData);
            this.$patch(state => {
                state.roomPkInfo = {};
                state.currentRoomInfo = {};
            });
        },
        async refreshRankList(){
            console.log('im断开重新刷新榜单');
            return await fly.getRefreshContributeList({roomId:this.currentRoomID})
        },
        //初始化TIM
        initTIM() {
            if (!this.timHandle) {
                this.timHandle = new IM();
                this.timHandle.init(); //初始化
                this.timHandle.initEvent(); //绑定事件
            }
        },

        //更新会话列表C2CList
        setConversationList(data) {
            this.conversationList = data.filter(item => {
                return item.type === "C2C" && !this.removeIdArr.includes(item.conversationID);
            });
        },

        //发送群消息
        async sendMsg(obj) {
            if (!obj || JSON.stringify(obj) === "{}") return;
            if(!sessionStorage.isGetUser){
                const { data, code } = await fly.getUserInfo();
                if (code === 0) {
                    this.userInfo = data;
                    localStorage.userInfo = JSON.stringify(data);
                }
                sessionStorage.isGetUser = 1
            }
            const { headPortrait, nickname, uid, level, gender, charmLevel, userGenre, chatBubbleMark, isVip, vipLevel } = this.userInfo;
            let msg = {
                GetEnvelopeNumber: 0,
                RedEnvelopeNumber: 0,
                count: 0,
                giftCount: 0,
                hidden: false,
                isNewUser: 0,
                microphoneNum: 0,
                radioStationMicSwitch: 0,
                sendUserId: 0,
                silenceTime: 0,
                sourceUserId: 0,
                type: 0,
                text: "",
                //已填信息
                mType: "Text",
                nickname,
                charm: charmLevel,
                userGenre,
                gender,
                headPortrait,
                level,
                userId: uid,
                receivedId: this.currentGroupID,
                roomId: this.currentRoomID,
                isVip,
                vipLevel,
                // roomId: this.currentRoomID,
                ...(chatBubbleMark ? { chatBubbleMark } : {}), //聊天气泡框
            };
            msg = { ...msg, ...(obj ? obj : {}) };
            if (this.timHandle && this.timHandle.isSdkReady) {
                this.timHandle
                    .sendGroupCustomMessage(this.currentGroupID, {
                        data: JSON.stringify(msg),
                        source: msg,
                        description: "",
                        extension: "",
                    })
            }
        },
        // async collectLog(){
        //     this.Log = markRaw(new Log(fly,this))
        // }
    },
});

export default {}//appStore;
